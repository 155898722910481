// @flow

import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';
import { ReactComponent as Download } from '../icons/DownloadSimple.svg';

import type { Component } from '../types/Component';

const FreeTrial: Component = withTranslation()((props) => (
  <>
    <div
      className='position-relative'
    >
      <div
        className='shape shape-bottom shape-fluid-x text-blue-dark'
      >
        <Curve1 />
      </div>
    </div>
    <section
      className='py-12 py-md-11 pb-md-12 bg-blue-dark text-light'
    >
      <div
        className='container'
      >
        <div
          className='row justify-content-center'
        >
          <div
            className='col-12 col-md-10 text-center'
          >
            {/* <span
              className='badge rounded-pill bg-gray-700-soft mb-4'
            >
              <span
                className='h6 fw-bold text-uppercase'
              >
                { props.t('FreeTrial.label') }
              </span>
            </span> */}
            <h1
              className='display-4'
            >
              <Trans
                i18nKey='FreeTrial.header'
              >
                Start your project today with
                <span
                  className='text-success'
                >
                  FairCopy
                </span>
              </Trans>
            </h1>
            <p
              className='fs-lg mb-6 mb-md-8'
            >
              { props.t('FreeTrial.content') }
            </p>
            <Link
              className='btn btn-success lift'
              to='/download'
            >
              { props.t('Home.buttons.download') }
              <Download className='ms-2' />
            </Link>
          </div>
        </div>
      </div>
    </section>
  </>
));

export default FreeTrial;
