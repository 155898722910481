// @flow

import React, { type Element } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import FreeTrial from '../components/FreeTrial';
import SignUpBanner from '../components/SignUpBanner';
import { ReactComponent as Blur1 } from '../shapes/blurs/blur-1.svg';
import { ReactComponent as Blur4 } from '../shapes/blurs/blur-4.svg';
import { ReactComponent as Book } from '../icons/duotone-icons/home/book.svg';
import { ReactComponent as BookOpen } from '../icons/duotone-icons/home/book-open.svg';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';
import { ReactComponent as Earth } from '../icons/duotone-icons/home/earth.svg';
import { ReactComponent as Globe } from '../icons/duotone-icons/home/globe.svg';
import { ReactComponent as Heart } from '../icons/duotone-icons/general/heart.svg';
import { ReactComponent as Laptop } from '../icons/duotone-icons/devices/laptop.svg';
import { ReactComponent as Share } from '../icons/duotone-icons/communication/share.svg';
import { ReactComponent as Write } from '../icons/duotone-icons/communication/write.svg';
import { ReactComponent as Download } from '../icons/DownloadSimple.svg';
import ss1 from '../img/screenshots/ss1.png';
import ss2 from '../img/screenshots/ss2.png';
import graphic1 from '../img/woman_at_desk.jpg';
import graphic3 from '../img/graphic-3.png';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

/**
 * Component used to display an icon with text.
 */
type IconTextProps = {
  content: string,
  header: string,
  icon: Element<any>
}

const IconText = (props: IconTextProps) => (
  <div
    className='col-12 col-md-4'
    data-aos='fade-up'
  >
    <div
      className='icon text-primary mb-3'
    >
      { props.icon }
    </div>
    <h3>
      { props.header }
    </h3>

    <p
      className='text-muted mb-6 mb-md-0'
    >
      { props.content }
    </p>
  </div>
);

/**
 * Component used to an item in a list with an icon.
 */
type ListItemProps = {
  header: string,
  icon: Element<any>,
  items: Array<string>
};

const ListItem = (props: ListItemProps) => (
  <div
    className='col-12 col-md-6 col-lg-4 text-center p-2'
    data-aos='fade-down'
  >
    <div className='bg-white pt-3 px-5 relative h-100'>
      <div
        className='icon text-primary icon-lg mb-4'
      >
        { props.icon }
      </div>
      <h3
        className='fw-bold mb-6 text-start'
      >
        { props.header }
      </h3>
      <ul
        className='list-group text-muted text-start mb-6 ms-4'
      >
        { _.map(props.items, (item) => (
          <li
            className='list-item'
          >
            { item }
          </li>
        ))}
      </ul>
    </div>
  </div>
);

/**
 * Home page component.
 *
 * @param props
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Home = (props: Translateable) => (
  <>
    <section
      className='position-relative py-10 py-md-11 mb-9'
    >
      <div
        className='shape shape-fluid-x shape-blur-1 text-gray-200'
      >
        <Blur1 />
      </div>
      <div
        className='container'
      >
        <div
          className='row align-items-center'
        >
          <div
            className='col-12 col-md-5 col-lg-6 order-md-2'
          >
            <div
              className='img-skewed img-skewed-start mb-8 mb-md-0'
            >
              <img
                alt='hero-screenshot'
                src={ss1}
                className='screenshot img-fluid mw-md-110'
                data-aos='img-skewed-item-start'
                data-aos-delay='100'
              />
            </div>
          </div>
          <div
            className='col-12 col-md-7 col-lg-6 order-md-1'
            data-aos='fade-up'
          >
            <h1
              className='display-3 text-center text-md-start'
            >
              { props.t('Home.header') }
            </h1>
            <p
              className='lead text-center text-md-start text-muted mb-6 mb-lg-8'
            >
              <span
                className='text-success fw-bold'
              >
                { props.t('Home.title') }
              </span>
              { props.t('Home.subheader') }
            </p>
            <div
              className='text-center text-md-start'
            >
              <Link
                className='btn btn-primary shadow lift me-1'
                to='/download'
              >
                { props.t('Home.buttons.download') }
                <Download className='ms-2' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className='py-8 pt-md-2 pb-md-8 border-bottom'
    >
      <div
        className='container'
      >
        <div
          className='row'
        >
          <IconText
            content={props.t('Home.labels.digitalEditions.content')}
            header={props.t('Home.labels.digitalEditions.header')}
            icon={<Write />}
          />
          <IconText
            content={props.t('Home.labels.closeReading.content')}
            header={props.t('Home.labels.closeReading.header')}
            icon={<Book />}
          />
          <IconText
            content={props.t('Home.labels.internationalStandards.content')}
            header={props.t('Home.labels.internationalStandards.header')}
            icon={<Globe />}
          />
        </div>
      </div>
    </section>
    <div
      className='position-relative'
    >
      <div
        className='shape shape-bottom shape-fluid-x text-gray-100'
      >
        <Curve1 />
      </div>
    </div>
    <section
      className='pt-8 pt-md-11 pb-md-8 bg-gradient-light-white'
    >
      <div
        className='container'
      >
        <div
          className='row align-items-center justify-content-between mb-8 mb-md-11'
        >
          <div
            className='col-12 col-md-6 order-md-1'
            data-aos='fade-right'
          >
            <h2>
              { props.t('Home.labels.madeEasy.header') }
              <span
                className='text-success'
                data-typed={JSON.stringify({
                  strings: [
                    props.t('Home.labels.madeEasy.options.easy'),
                    props.t('Home.labels.madeEasy.options.elegant'),
                    props.t('Home.labels.madeEasy.options.efficient')
                  ]
                })}
              />
            </h2>
            <p
              className='fs-lg text-muted mb-6'
            >
              <span
                className='text-success'
              >
                { props.t('Home.title') }
              </span>
              { props.t('Home.labels.madeEasy.paragraph1') }
              <br />
              <br />
              { props.t('Home.labels.madeEasy.paragraph2') }
            </p>
          </div>
          <div
            className='col-12 col-md-6 col-lg-5 order-md-2'
            data-aos='fade-left'
          >
            <img
              alt='woman-at-desk'
              className='card-img-top'
              src={graphic3}
            />
          </div>
        </div>
        <div
          className='row align-items-center mb-8 mb-md-12'
        >
          <div
            className='col-12 col-md-6 col-lg-6 order-md-2'
            data-aos='fade-right'
          >
            <h2>
              { props.t('Home.labels.advancedEditing.header') }
            </h2>
            <p
              className='fs-lg text-muted mb-6'
            >
              <span
                className='text-success'
              >
                { props.t('Home.title_possessive') }
              </span>
              { props.t('Home.labels.advancedEditing.content') }
            </p>
          </div>
          <div
            className='col-12 col-md-6 order-md-1'
          >
            <div
              className='w-md-75 w-lg-100 position-relative'
              data-aos='fade-left'
            >
              <div
                className='shape shape-fluid-y shape-blur-4 text-gray-200'
              >
                <Blur4 />
              </div>
              <div
                className='img-skewed img-skewed-end'
              >
                <img
                  alt='screenshot-2'
                  className='screenshot img-fluid img-skewed-item'
                  src={ss2}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className='row align-items-center justify-content-between'
        >
          <div
            className='col-12 col-md-6 order-md-1'
            data-aos='fade-left'
          >
            <h2>
              { props.t('Home.labels.transcribe.header') }
            </h2>
            <p
              className='fs-lg text-muted mb-6'
            >
              <span
                className='text-success'
              >
                { props.t('Home.title') }
              </span>
              { props.t('Home.labels.transcribe.paragraph1') }
              <br />
              <br />
              { props.t('Home.labels.transcribe.paragraph2') }
            </p>
          </div>
          <div
            className='col-12 col-md-6 col-lg-5 order-md-2'
            data-aos='fade-right'
          >
            <img
              alt='tei-graphic'
              className='screenshot'
              src={graphic1}
            />
          </div>
        </div>
      </div>
    </section>
    <FreeTrial
      className='py-12 py-md-11 pb-md-12'
    />
    <div
      className='position-relative mt-n8'
    >
      <div
        className='shape shape-bottom shape-fluid-x text-gray-200'
      >
        <Curve1 />
      </div>
    </div>
    <section
      className='pt-8 pt-md-9 bg-gray-200'
    >
      <div
        className='container'
      >
        <div
          className='row align-items-center justify-content-center'
        >
          <div
            className='col-12 text-center pb-8'
          >
            <h1>
              <Trans
                i18nKey='Home.labels.features.header'
              >
                All the features
                <br />
                <span
                  className='text-success'
                >
                  digital humanists need.
                </span>
              </Trans>
            </h1>
          </div>
        </div>
        <div
          className='row'
        >
          <ListItem
            header={props.t('Home.labels.features.gettingStarted.header')}
            icon={<Laptop />}
            items={[
              props.t('Home.labels.features.gettingStarted.item1'),
              props.t('Home.labels.features.gettingStarted.item2'),
              props.t('Home.labels.features.gettingStarted.item3')
            ]}
          />
          <ListItem
            header={props.t('Home.labels.features.customize.header')}
            icon={<BookOpen />}
            items={[
              props.t('Home.labels.features.customize.item1'),
              props.t('Home.labels.features.customize.item2'),
              props.t('Home.labels.features.customize.item3')
            ]}
          />
          <ListItem
            header={props.t('Home.labels.features.edit.header')}
            icon={<Write />}
            items={[
              props.t('Home.labels.features.edit.item1'),
              props.t('Home.labels.features.edit.item2'),
              props.t('Home.labels.features.edit.item3')
            ]}
          />
          <ListItem
            header={props.t('Home.labels.features.collaborate.header')}
            icon={<Share />}
            items={[
              props.t('Home.labels.features.collaborate.item1'),
              props.t('Home.labels.features.collaborate.item2'),
              props.t('Home.labels.features.collaborate.item3')
            ]}
          />
          <ListItem
            header={props.t('Home.labels.features.control.header')}
            icon={<Earth />}
            items={[
              props.t('Home.labels.features.control.item1'),
              props.t('Home.labels.features.control.item2'),
              props.t('Home.labels.features.control.item3')
            ]}
          />
          <ListItem
            header={props.t('Home.labels.features.friendly.header')}
            icon={<Heart />}
            items={[
              props.t('Home.labels.features.friendly.item1'),
              props.t('Home.labels.features.friendly.item2')
            ]}
          />
        </div>
      </div>
    </section>
    <SignUpBanner extend />
    <div
      className='position-relative'
    >
      <div
        className='shape shape-bottom shape-fluid-x text-gray-200'
      >
        <Curve1 />
      </div>
    </div>
  </>
);

const HomePage: Component = withTranslation()(Home);
export default HomePage;
