// @flow

import React from 'react';
import { useDragDrop } from 'react-components';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import Contact from './pages/Contact';
import Download from './pages/Download';
import Home from './pages/Home';
import './App.css';

import type { Component } from './types/Component';
import Confirmation from './pages/Confirmation';
import SubscribeConfirmation from './pages/SubscribeConfirmation';

// Lazy load the layout components
const Layout = React.lazy(() => import('./components/Layout'));

const App = () => (
  <Router>
    <Routes>
      <Route
        element={(
          <React.Suspense
            fallback={<></>}
          >
            <Layout />
          </React.Suspense>
        )}
        path='/'
      >
        <Route
          element={<Home />}
          index
        />
        <Route
          element={<Contact />}
          path='/contact'
        />
        <Route
          element={<Download />}
          path='/download'
        />
        <Route
          element={<Confirmation />}
          path='/confirmation'
        />
        <Route
          element={<SubscribeConfirmation />}
          path='/signup-confirmation'
        />
        <Route
          element={(
            <Navigate
              replace
              to='/'
            />
          )}
          path='*'
        />
      </Route>
    </Routes>
  </Router>
);

const AppComponent: Component = useDragDrop(App);
export default AppComponent;
