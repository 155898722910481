// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';

import type { Component } from '../types/Component';

const SubscribeConfirmation: Component = withTranslation()((props) => (
  <div>
    <section
      className='section-border border-primary bg-gray-200'
    >
      <div
        className='container d-flex flex-column py-10'
      >
        <div
          className='row justify-content-center gx-0'
        >
          <div
            className='col-12 col-md-8'
          >
            <h1
              className='mb-4 fw-bold text-center'
            >
              { props.t('SignUpSection.confirmation.header') }
            </h1>
          </div>
        </div>
        <div className='row justify-content-center'>
          <p className='col-12 text-center'>
            { props.t('SignUpSection.confirmation.content') }
          </p>
        </div>
      </div>
    </section>
  </div>
));

export default SubscribeConfirmation;
