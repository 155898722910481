// @flow

import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';

import type { Component } from '../types/Component';

const SignUpBanner: Component = withTranslation()((props) => (
  <>
    <div
      className={`position-relative${props.extend ? ' mt-n15' : ''}`}
    >
      <div
        className='shape shape-bottom shape-fluid-x text-blue-dark'
      >
        <Curve1 />
      </div>
    </div>
    <section
      className='py-12 py-md-11 pb-md-12 bg-blue-dark text-light pt'
    >
      { props.extend && <div style={{ width: '100%', height: '270px' }} /> }
      <div
        className='container'
      >
        <div
          className='row justify-content-center'
        >
          <div
            className='col-12 text-center'
          >
            <h1
              className='display-4 text-success'
            >
              <Trans
                i18nKey='SignUpSection.header'
              >
                Signup
                <span className='text-white'>
                  for the latest updates
                </span>
              </Trans>
            </h1>
            <p
              className='fs-lg mb-6 mb-md-8'
            >
              { props.t('SignUpSection.content') }
            </p>
            <div id='mc_embed_signup'>
              <form action='https://performantsoftware.us7.list-manage.com/subscribe/post?u=48346306fd7ec4c1e38387774&amp;id=13cbe5f7a0&amp;f_id=0024afe4f0' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' className='validate' target='_blank'>
                <div id='mc_embed_signup_scroll'>
                  <div className='mc-field-group w-100 column-gap-2'>
                    <input type='text' autoComplete='given-name' placeholder={props.t('SignUpSection.form.firstName')} id='mce-FNAME' className='p-3 me-4 rounded-2' />
                    <input type='text' autoComplete='family-name' placeholder={props.t('SignUpSection.form.lastName')} id='mce-LNAME' className='p-3 me-4 rounded-2' />
                    <input type='email' autoComplete='email' placeholder={props.t('SignUpSection.form.email')} name='EMAIL' className='required email p-3 me-4 rounded-2' id='mce-EMAIL' required='' />
                    <input type='submit' name='subscribe' id='mc-embedded-subscribe' className='button rounded-2 p-3 btn btn-success lift' value={props.t('SignUpSection.form.subscribe')} />
                  </div>
                  <div id='mce-responses' className='clear'>
                    <div className='response' id='mce-error-response' style={{ display: 'none' }} />
                    <div className='response' id='mce-success-response' style={{ display: 'none' }} />
                  </div>
                  <div aria-hidden='true' style={{ position: 'absolute', left: '-5000px' }}>
                    <input type='text' name='b_48346306fd7ec4c1e38387774_13cbe5f7a0' tabIndex='-1' value='' />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
));

export default SignUpBanner;
