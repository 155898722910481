// @flow

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import type { Component } from '../types/Component';

// eslint-disable-next-line prefer-template
const encode = (data) => (Object.keys(data).map((key) => (encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))).join('&'));

const Contact: Component = withTranslation()((props) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    institution: '',
    message: '',
    emailSignup: false,
  });

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...state })
    })
      .then(() => {
        window.location.href = '/confirmation';
      })
      .catch((error) => { throw new Error(error.message); });
    e.preventDefault();
    setState({
      name: '',
      email: '',
      message: '',
      institution: '',
      emailSignup: false,
    });
  };

  const handleToggleContentConsent = () => {
    setState((current) => ({ ...current, emailSignup: !current.emailSignup }));
  };

  return (
    <div>
      <section
        className='section-border border-primary bg-gray-200'
      >
        <div
          className='container d-flex flex-column py-10'
        >
          <div
            className='row justify-content-center gx-0'
          >
            <div
              className='col-12 col-md-8'
            >
              <h1
                className='mb-4 fw-bold text-center'
              >
                { props.t('Contact.header') }
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-white'>
        <div className='container d-flex flex-column py-6'>
          <form onSubmit={handleSubmit} className='bg-white p-8 rounded-2 col-12 d-flex flex-column row-gap-3'>
            <input type='hidden' name='form-name' value='contact' />
            <div className='d-flex flex-column row-gap-4'>
              <div className='d-flex flex-column py-3'>
                <label htmlFor='email' className='font-weight-bold' style={{ fontWeight: 700 }}>{ props.t('Contact.form.email')}</label>
                <div className='mt-2'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    required
                    autoComplete='email'
                    value={state.email}
                    onChange={handleChange}
                    className='bg-gray-200 p-3 rounded-2 border-0 w-100'
                  />
                </div>
              </div>
              <div className='flex flex-col py-3'>
                <label htmlFor='name' className='font-weight-bold' style={{ fontWeight: 700 }}>{ props.t('Contact.form.name')}</label>
                <div className='mt-2'>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    autoComplete='name'
                    value={state.name}
                    onChange={handleChange}
                    className='bg-gray-200 p-3 rounded-2 border-0 w-100'
                  />
                </div>
              </div>
              <div className='flex flex-col py-3'>
                <label htmlFor='email' className='font-weight-bold' style={{ fontWeight: 700 }}>{ props.t('Contact.form.institution')}</label>
                <div className='mt-2'>
                  <input
                    id='institution'
                    name='institution'
                    type='text'
                    value={state.institution}
                    onChange={handleChange}
                    className='bg-gray-200 p-3 rounded-2 border-0 w-100'
                  />
                </div>
              </div>
              <div className='flex flex-col py-3'>
                <label htmlFor='message' className='font-weight-bold' style={{ fontWeight: 700 }}>{ props.t('Contact.form.message')}</label>
                <div className='mt-2'>
                  <textarea
                    id='message'
                    name='message'
                    value={state.message}
                    onChange={handleChange}
                    className='bg-gray-200 p-3 rounded-2 border-0 w-100'
                  />
                </div>
              </div>
            </div>
            <div className='py-3'>
              <div className='mt-5 space-y-10'>
                <div className='space-y-6'>
                  <div className='relative flex gap-x-3'>
                    <div className='d-flex h-6 align-items-center'>
                      <input
                        id='emailSignup'
                        name='emailSignup'
                        type='checkbox'
                        className='rounded border-gray-300 d-inline-flex'
                        checked={state.emailSignup}
                        onChange={handleToggleContentConsent}
                      />
                      <label htmlFor='emailSignup' className='text-sm ms-4'>
                        { props.t('Contact.form.stayUpdated')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-start gap-x-6 pt-3'>
              <button
                type='submit'
                className='btn btn-success'
              >
                <span>{ props.t('Contact.form.submit') }</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
});

export default Contact;
