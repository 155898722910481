// @flow

import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
// import Modal from '../components/Modal';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';

// Images
import appleLogo from '../img/apple.svg';
import windowsLogo from '../img/windows.svg';
// import linuxLogo from '../img/linux.svg';
// import linuxSetup from '../img/linux-setup.png';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';
import SignUpBanner from '../components/SignUpBanner';

type ListItemProps = Translateable & {
  header: string,
  image: {
    alt: string,
    src: string
  },
  url: string
};

// type LicenseBoxProps = Translateable & {
//   licenseKey: string
// };

/**
 * Renders the list item component. This component is responsible for rendering each item in the list of installers.
 *
 * @constructor
 */
const ListItem = withTranslation()((props: ListItemProps) => (
  <a
    className='btn btn-primary d-flex flex-row justify-content-center align-items-center'
    href={props.url}
    style={{ width: '320px' }}
  >
    <img
      alt={props.image.alt}
      src={props.image.src}
      style={{ maxHeight: 'none', paddingRight: '16px' }}
    />
    <span style={{ fontSize: '31px' }}>
      { props.header }
    </span>
  </a>
));

/**
 * Renders the license box component. This component is responsible for rendering the license key.
 *
 * @constructor
 */
// const LicenseBox = ((props: LicenseBoxProps) => (
//   <ul
//     className='list-group list-unstyled shadow'
//   >
//     <li
//       className='list-group-item d-flex align-items-center'
//     >
//       { props.licenseKey }
//       <span
//         className='ms-auto'
//       >
//         <CopyButton
//           className='btn btn-secondary-soft shadow lift btn-rounded-circle mx-2 position-relative'
//           text={props.licenseKey}
//         />
//       </span>
//     </li>
//   </ul>
// ));

/**
 * Renders the download component. This component is responsible for displaying download/installation instructions
 * and the purchased license key.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Download = () => {
  // const [url, setUrl] = useState();
  // const [userLicense, setUserLicense] = useState();

  // const { id } = useParams();
  const { t } = useTranslation();

  /**
   * When the user licenses are loaded, grab the user associated with the first license and generate a portal
   * session URL.
   */
  // useEffect(() => {
  //   if (userLicense) {
  //     Customers
  //       .session(userLicense.user.stripe_id, { url: window.location.href })
  //       .then(({ data }) => setUrl(data.url));
  //   }
  // }, [userLicense]);

  /**
   * Load the user licenses for the passed payment_intent_id when the component loads.
   */
  // useEffect(() => {
  //   UserLicenses
  //     .fetchAll({ secure_id: id })
  //     .then(({ data }) => setUserLicense(_.first(data.user_licenses)));
  // }, []);

  return (
    <>
      <div
        className='bg-gradient-light-white'
      >
        <section
          className='py-4'
        >
          <div
            className='container'
          >
            <div
              className='row justify-content-center'
            >
              <div
                className='col-12 col-md-8 text-center'
              >
                <h1
                  className='fw-bold'
                >
                  { t('Download.title') }
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div
          className='position-relative'
        >
          <div
            className='shape shape-bottom shape-fluid-x text-light'
          >
            <Curve1 />
          </div>
        </div>
        <section
          className='pt-6 pt-md-8 mb-md-2'
        >
          <div
            className='container'
          >
            <div
              className='row'
            >
              <div
                className='col-12'
              >
                <h2>
                  { t('Download.labels.download.header') }
                </h2>
                <p
                  className='text-muted'
                >
                  { t('Download.labels.download.subheader') }
                </p>
                <div
                  className='d-flex flex-row justify-content-evenly'
                >
                  <ListItem
                    header={t('Download.labels.os.mac')}
                    image={{
                      alt: 'Apple Logo',
                      src: appleLogo
                    }}
                    url={process.env.REACT_APP_INSTALLER_MAC}
                  />
                  <ListItem
                    header={t('Download.labels.os.windows')}
                    image={{
                      alt: 'Windows Logo',
                      src: windowsLogo
                    }}
                    url={process.env.REACT_APP_INSTALLER_WINDOWS}
                  />
                  {/* <ListItem
                    header={t('Download.labels.os.linux')}
                    image={{
                      alt: 'Linux Logo',
                      src: linuxLogo
                    }}
                    url={process.env.REACT_APP_INSTALLER_LINUX}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='pt-6 pt-md-8 mb-md-8'
        >
          <div
            className='container'
          >
            <div
              className='row'
            >
              <div
                className='col-12'
              >
                <h2>
                  { t('Download.labels.run.header') }
                </h2>
                <p
                  className='text-muted'
                >
                  { t('Download.labels.run.subheader') }
                </p>
                {/* <button
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#linuxModal'
                  type='button'
                >
                  { t('Download.buttons.linux') }
                </button> */}
              </div>
            </div>
          </div>
          {/* <Modal
            className='modal-lg'
            closeable
            id='linuxModal'
            title={t('Download.labels.linuxModal.header')}
          >
            <div>
              { t('Download.labels.linuxModal.content')}
              <br />
              <br />
              <img
                alt='Linux Instructions'
                src={linuxSetup}
              />
            </div>
          </Modal> */}
        </section>
        <SignUpBanner />
        <div
          className='position-relative'
        >
          <div
            className='shape shape-bottom shape-fluid-x text-gray-200'
          >
            <Curve1 />
          </div>
        </div>
      </div>
    </>
  );
};

const DownloadPage: Component = withTranslation()(Download);
export default DownloadPage;
